import React from 'react'
import "./PageNotFound.css"

const FullPageLoader = () => {
  return (
    // <div>
      <div className='loader-wrapper'>
    <div className="ml-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  </div>
  // </div>
  )
}

export default FullPageLoader